@media screen and (min-width: 200px) {
    .shop-basket {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        color: $text;
        padding-bottom: 0px;
        overflow: auto;
        max-height: 90vh;
    
        &__title {
            font-size: 1.1rem;
            margin-bottom: 10px;
            font-weight: 600;
        }
    
        &__content-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
        }
    }
}

@media screen and (min-width: 768px ) {
    .shop-basket {
        padding-bottom: 10px;
    
        &__title {
            font-size: 1.3rem;
            margin-bottom: 20px;
        }
    }
}

@media screen and (min-width: 1024px) {
    .shop-basket {
        min-width: 800px;
        padding-bottom: 20px;
    
        &__title {
            font-size: 1.6rem;
            margin-bottom: 20px;
        }
    
        &__content-wrapper {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }
    }
}