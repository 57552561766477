@media screen and (min-width: 200px) {
    .order-realization-page {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        color: $text;
    
        &__products-box {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 70%;
            margin-right: 20px;
        }
    
        &__summary-box {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 30%;
        }
    
        &__content-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
        }
    }
}

@media screen and (min-width: 1024px) {
    .order-realization-page {
        &__content-wrapper {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }
    }
}