@media screen and (min-width: 200px) {
    .latest-recipe {
        position: relative;
        width: 100%;

        margin-bottom: $global-padding;
        border-radius: $border-radius;

        .latest-recipe-content {
            width: 100%;
            height: 100%;

            position: absolute;
            inset: 0;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            padding: $global-padding;
            color: $sub-text;
            text-align: center;
            background-color: $modal-backdrop;
            border-radius: $border-radius;
            z-index: 1;
            opacity: 0;
            transition: opacity 1s ease-in-out;


            &__photo {
                width: 100%;
                height: 100%;
                position: absolute;
                inset: 0;
                object-fit: cover;
                border-radius: $border-radius;
            }

            &__title {
                font-size: 1.5rem;
                font-weight: 600;
                overflow: hidden;
                white-space:nowrap;
                text-overflow: ellipsis;
                max-width: 100%;
            }

            &__text {
                font-size: 1.4rem;
                font-weight: 500;
                width: 100%;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
            }

            a {
                text-decoration: none;
            }
        }

        .latest-recipe-content:hover {
            opacity: 1;
        }

        .active {
            opacity: 1;
        }
    }
    
    .latest-recipe::before {
        content: "";
        display: block;
        padding-top: 100%;
        border-radius: $border-radius;
    }
}

@media screen and (min-width: 400px) {
    .latest-recipe {
        .latest-recipe-content {

            &__title {
                font-size: 2rem;
                font-weight: 600;
            }

            &__text {
                font-size: 1.7rem;
                font-weight: 500;
            }
        }
    }
}

@media screen and (min-width: 760px) {
    .latest-recipe {
        width: 85%;
        margin-bottom: $global-padding * 2;

        .latest-recipe-content {

            &__title {
                font-size: 2.2rem;
                font-weight: 600;
            }

            &__text {
                font-size: 2rem;
                font-weight: 600;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .latest-recipe {
        width: 22%;
        .latest-recipe-content {

            &__title {
                font-size: 1.2rem;
                font-weight: 600;
            }

            &__text {
                font-size: 1rem;
                font-weight: 600;
            }
        }
    }
}

@media screen and (min-width: 1366px) {
    .latest-recipe {
        .latest-recipe-content {

            &__title {
                font-size: 1.4rem;
                font-weight: 600;
            }

            &__text {
                font-size: 1.2rem;
                font-weight: 600;
            }
        }
    }
}

@media screen and (min-width: 1366px) {
    .latest-recipe {
        .latest-recipe-content {

            &__title {
                font-size: 1.8rem;
                font-weight: 600;
            }

            &__text {
                font-size: 1.6rem;
                font-weight: 600;
            }
        }
    }
}