@media screen and (min-width: 200px) {
    .about-page {
        width: 100%;

        border-radius: $border-radius;
        background: $background;

        padding: 15px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .about-image {
            width: 100%;
            border-radius: $border-radius;
            height: 300px;
            object-fit: cover;
            object-position: center -80px;
        }

        .about-image:nth-child(2) {
            object-position: center 0;
        }

        .about-text {
            width: 100%;
            text-align: justify;
            font-size: 0.9rem;
            font-weight: 400;
            line-height: 1.3rem;
            margin: 20px 0;
            margin-bottom: 0;
            hyphens: auto;

            p {
                margin-bottom: 20px;
            }

            &__title--bold {
                font-weight: 600;
            }

            ul {
                width: 100%;
                padding: 0 20px;
                margin-bottom: 10px;

                li {
                    list-style: none;
                    text-align: left;
                    font-size: 0.75rem;
                    margin-bottom: 10px;
                }
            }


            ul li::before {
                content: "\2022";
                color: $button;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
            }

            .navigation-to-shop-text {
                font-weight: 600;
                font-size: 0.8rem;
            }
        }

        .bottom-part {
            margin-top: 0;
        }

        .separator {
            width: 100%;
            border: 2px solid $sub-background;
            margin: 0 20px
        }
    }
}

@media screen and (min-width: 400px) {
    .about-page {
        padding: 20px;

        .about-text {
            width: 100%;
            text-align: justify;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.3rem;
            margin: 20px 0;
            margin-bottom: 0;

            p {
                margin-bottom: 20px;
            }

            &__title--bold {
                font-weight: 600;
                text-align: center;
            }

            ul {
                width: 100%;
                padding: 0 20px;
                margin-bottom: 10px;

                li {
                    text-align: left;
                    font-size: 0.85rem;
                    margin-bottom: 10px;
                }
            }

            .navigation-to-shop-text {
                font-weight: 600;
                font-size: 1rem;
                text-align: center;
            }
        }

        .bottom-part {
            margin-top: 0;
        }
    }
}

@media screen and (min-width: 768px) {
    .about-page {
        padding: 20px;

        .about-image {
            height: 500px;
            object-position: center -200px;
        }

        .about-text {
            width: 100%;
            text-align: justify;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.9rem;
            margin: 25px 0;
            margin-bottom: 0;

            ul {
                width: 100%;
                padding: 0 30px;
                margin-bottom: 10px;

                li {
                    text-align: left;
                    font-size: 1.1rem;
                    padding-left: 5px;
                    margin-bottom: 10px;
                }
            }

            .navigation-to-shop-text {
                font-weight: 600;
                font-size: 1.4rem;
                text-align: center;
            }
        }

        .bottom-part {
            margin-top: 0;
        }

        .separator {
            margin: 0 25px;
            margin-top: 10px;
        }
    }
}

@media screen and (min-width: 1024px) {
    .about-page {
        padding: 20px;
        
        .row {
            flex-direction: column;
        }

        .about-image {
            width: 100%;
            height: 500px;
            object-fit: cover;
            object-position: center -320px;
            margin-bottom: 20px;
        }
        .about-text {
            width: 100%;
            text-align: justify;
            font-size: 1.6rem;
            font-weight: 300;
            line-height: 2rem;
            margin: 0;
            hyphens: none;

            ul {
                width: 100%;
                padding: 0 40px;
                margin-bottom: 10px;

                li {
                    text-align: left;
                    font-size: 1.3rem;
                    padding-left: 5px;
                    margin-bottom: 10px;
                }

                
            }
        }
        
        .separator {
            margin: 25px
        }
    }
}

@media screen and (min-width: 1280px) {
    .about-page {
        .about-image {
            height: 600px;
            object-position: center -350px;
        }
    }
}

@media screen and (min-width: 1360px) {
    .about-page {
        .about-image {
            height: 650px;
            object-position: center -380px;
        }
    }
}

@media screen and (min-width: 1800px) {
    .about-page {
        .about-image {
            height: 750px;
            object-position: center -500px;
        }
    }
}