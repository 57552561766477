@media screen and (min-width: 200px) {
    .single-product {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        color: $text;
        background: $modal-background;
        text-align: center;
        border-radius: $border-radius;
        margin-bottom: 30px;
        padding-bottom: $global-padding;

        img {
            width: 100%;
            object-fit: cover;
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
            margin-bottom: 15px;
        }

        img::after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }

        &__title {
            font-size: 1.2rem;
            font-weight: 600;
            margin-bottom: 15px;
            padding: 0 $global-padding;
        }

        &__description {
            font-size: 1rem;
            line-height: 1.2rem;
            font-weight: 500;
            padding: 0 $global-padding;
            margin-bottom: 15px;

        }
    }

    .order-1 {
        order: 1;
    }
    .order-2 {
        order: 2;
    }
    .order-3 {
        order: 3;
    }
}

@media screen and (min-width: 768px) {
    .single-product {
        width: 31%;
        height: 350px;

        &__title {
            font-size: 1rem;
            padding: 0 10px;
        }

        &__description {
            font-size: 1rem;
            line-height: 1.12rem;
        }

        button {
            font-size: 0.75rem;
            padding: 8px 12px;
        }
    }
}

@media screen and (min-width: 1024px) {
    .single-product {
        width: 31%;
        height: 450px;

        &__title {
            font-size: 1.2rem;
            padding: 0 15px;
        }

        &__description {
            font-size: 1.1rem;
            line-height: 1.25rem;
        }

        button {
            font-size: 1rem;
        }
    }
}

@media screen and (min-width: 1366px) {
    .single-product {
        height: 550px;


        &__title {
            font-size: 1.6rem;
        }

        &__description {
            font-size: 1.2rem;
            line-height: 1.35rem;
        }
    }
}

@media screen and (min-width: 1800px) {
    .single-product {
        height: 660px;


        &__title {
            font-size: 2rem;
        }

        &__description {
            font-size: 1.4rem;
            line-height: 1.55rem;
        }

        button {
            font-size: 1.3rem;
            padding: 14px 24px;
        }
    }
}