@media screen and (min-width: 200px) {
    .form-wrapper {
        width: 100%;
        .form {
            max-height: 80vh;
            max-width: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            // flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
    
            .input-wrapper {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 8px;
                position: relative;
    
                label {
                    font-size: 0.8rem;
                    font-weight: 600;
                    color: $text;
                }
    
                input, select, textarea {
                    outline: none;
                    border: none;
                    background: $background;
                    border-radius: $border-radius;
                    padding: 8px 12px;
                    margin: 5px;
                    color: $text;
                    height: 40px;
                }
    
                textarea {
                    resize: none;
                    height: 80px;
                }


            }

            .input-wrapper .required-field+span::after {
                content: "*";
                font-size: 1.5rem;
                color: $button;
                position: absolute;
                right: 20px;
                top: 50%;
            }

            .button-wrapper {
                width: 100%;
                padding: 0 5px;
            }

            button {
                width: 100%;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .form-wrapper {
        .form {
            flex-direction: row;
            flex-wrap: wrap;
    
            .input-wrapper {
                width: 50%;
    
                label {
                    font-size: 0.9rem;
                    font-weight: 600;
                    color: $text;
                }
    
                input, select, textarea {
                    outline: none;
                    border: none;
                    background: $background;
                    border-radius: $border-radius;
                    padding: 8px 12px;
                    margin: 5px;
                    color: $text;
                    height: 40px;
                }
    
                textarea {
                    resize: none;
                    height: 40px;
                }
            }

            button {
                padding: 10px 12px;
                margin: 10px 0;
            }
        }
    }
}