@media screen and (min-width: 200px) {
    .header {
        width: 100%;
        height: auto;
        background-color: $background;
        
        display: flex;
        justify-content: center;
        
        margin-bottom: $bottom-separator-margin-small;

        .logo-wrapper {
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
            background-color: $modal-background;

            padding: 10px;
            padding-bottom: 5px;

            &__logo {
                width: 185px;
            }
        }

        .user-nav-wrapper {
            position: absolute;
            display: flex;
            flex-direction: column;
            top: 55px;
            right: 15px;
        }
    }
}

@media screen and (min-width: 760px) {
    .header {
        .logo-wrapper {
            &__logo {
                width: 220px;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .header {
        .logo-wrapper {
            &__logo {
                width: 180px;
            }
        }
        
        .user-nav-wrapper {
            position: absolute;
            flex-direction: row;
            top: 22px;
            right: 0;
        }
    }
}

@media screen and (min-width: 1800px) {
    .header {
        .logo-wrapper {
            &__logo {
                width: 220px;
            }
        }
    }
}