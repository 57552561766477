@media screen and (min-width: 200px) {
    .main-about-me-component {
        width: 100%;
        height: auto;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-bottom: $bottom-separator-margin;

        &__photo {
            width: 100%;

            img {
                width: 100%;
                height: 330px;

                object-fit: cover;
                object-position: 0 -20px;
                
                border-radius: $border-radius;
            }
        }

        &__about {
            width: 100%;
            text-align: justify;
            hyphens: auto;
            color: $text;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &__title {
                font-size: 1.8rem;
                font-weight: 600;
                margin: 15px 0;
            }

            &__text {
                font-size: 1.2rem;
                font-weight: 500;
                line-height: 1.4rem;
                margin-bottom: 25px;
            }

            a {
                text-decoration: none;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .main-about-me-component {
        &__photo {
            img {
                height: 580px;
            }
        }

        &__about {
            &__title {
                font-size: 2.5rem;
                font-weight: 600;
                margin: 20px 0;
            }

            &__text {
                font-size: 1.8rem;
                font-weight: 500;
                line-height: 2rem;
                margin-bottom: 30px;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .main-about-me-component {
        flex-direction: row;
        align-items: center;
        padding-bottom: 0;
        margin-bottom: $bottom-separator-margin;
        &__photo {
            width: 100%;
            height: 100%;
            margin-right: 25px;
            img {
                height: 650px;
                object-position: center;
            }
        }

        &__about {
            margin-left: 25px;
            &__title {
                width: 100%;
                font-size: 2.2rem;
            }

            &__text {
                font-size: 1.55rem;
                line-height: 1.8rem;
                margin-bottom: 25px;
            }
        }
    }
}

@media screen and (min-width: 1280px) {
    .main-about-me-component {
        &__photo {
            width: 100%;
            height: 100%;
            margin-right: 55px;
            img {
                height: 700px;
            }
        }

        &__about {
            margin-left: 55px;
            &__title {
                width: 100%;
                font-size: 2.5rem;
            }

            &__text {
                font-size: 1.6rem;
                line-height: 2rem;
                margin-bottom: 30px;
            }
        }
    }
}

@media screen and (min-width: 1800px) {
    .main-about-me-component {
        &__about {
            &__title {
                width: 100%;
                font-size: 3.1rem;
                margin-bottom: 30px;
            }

            &__text {
                font-size: 1.9rem;
                line-height: 2.1rem;
                margin-bottom: 40px;
            }
        }
    }
}