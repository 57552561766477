@media screen and (min-width: 200px) {
    .latest-recipes-component-wrapper {
        width: 100%;
        height: auto;

        display: flex;
        justify-content: center;
        align-items: center;

        background: $sub-background;
        padding: $global-padding 0;
        margin-bottom: $bottom-separator-margin;

        .latest-recipes {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &__title {
                text-align: center;
                font-size: 1.2rem;
                font-weight: 600;
                color: $text;
                margin-bottom: 20px;
            }

            &__wrapper {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .latest-recipes-component-wrapper  {
        .latest-recipes {
            &__title {
                font-size: 1.5rem;
                margin: 10px 0;
                margin-bottom: 30px;
            }

            &__wrapper {
                flex-direction: row;
            }
        }
    }
}

@media screen and (min-width: 1366px) {
    .latest-recipes-component-wrapper  {
        .latest-recipes {
            &__title {
                font-size: 1.7rem;
            }
        }
    }
}

@media screen and (min-width: 1800px) {
    .latest-recipes-component-wrapper  {
        .latest-recipes {
            &__title {
                margin: 20px 0;
                margin-bottom: 40px;
                font-size: 2rem;
            }
        }
    }
}