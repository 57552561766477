@media screen and (min-width: 200px) {
    .hamburger-menu-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100vh;
    
        background: $modal-background;
        z-index: 100;
    
        position: fixed;
        inset: 0;
        left: -100%;
    
        padding: $global-padding + 20px;

        transition: left .3s ease;

        .hamburger-menu {
            width: 100%;
            height: 100%;
            
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        
            &__logo {
                width: 70%; 
            }

            &__nav {
                width: 100%;
                height: 100%;

                ul {
                    width: 100%;
                    height: 100%;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    li {
                        margin-bottom: 30px;
                    }

                    a {
                        color: $text;
                        user-select: none;
                        text-decoration: none;

                        font-size: 1.5rem;

                        padding: 5px;
                    }

                    .active-link {
                        border-bottom: 3px solid $button;
                    }
                }
            }
        }
    }

    .hamburger-menu-wrapper.visible {
        left: 0;
    }
}

@media screen and (min-width: 1024px) {
    .hamburger-menu-wrapper {
        width: auto;
        right: auto;

        .hamburger-menu {
            width: auto;
            &__logo {
                width: 200px; 
            }

            &__nav {
                ul {
                    a {
                        font-size: 1.8rem;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1280px) {
    .hamburger-menu-wrapper {
        .hamburger-menu {
            &__logo {
                width: 240px; 
            }

            &__nav {
                ul {
                    a {
                        font-size: 2.25rem;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1366px) {
    .hamburger-menu-wrapper {
        .hamburger-menu {
            &__logo {
                width: 200px;
                margin-bottom: 20px;
            }
        }
    }
}

@media screen and (min-width: 1800px) {
    .hamburger-menu-wrapper {
        .hamburger-menu {
            padding: 0 20px;
            &__logo {
                width: 360px; 
            }

            &__nav {
                ul {
                    a {
                        font-size: 3.35rem;
                    }
                }
            }
        }
    }
}