@media screen and (min-width: 200px) {
    .single-product-shop-view {
        width: 100%;
        margin-bottom: 10px;

        &__img {
            width: 100%;
            border-radius: $border-radius;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            object-fit: cover;
        }

        &__img::after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }

        &__content-wrapper {
            background: $background;
            padding: 10px 15px;
            color: $text;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            border-bottom-right-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
        }

        &__title {
            font-size: 1.2rem;
            margin-bottom: 7px;
            display: none;
        }

        &__description {
            font-size: 1rem;
            font-weight: 300;
            margin-bottom: 10px;
        }

        &__actions-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }

        &__diet-length-action {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;

            h4 {
                font-size: 1rem;
                font-weight: 300;
                margin-right: 10px;
            }

            .select-dropdown {
                position: relative;
                background-color: $sub-background;
                width: auto;
                max-width: 100%;
                border-radius: $border-radius;

                select {
                    color: $text;
                    font-size: 1rem;
                    font-weight: 300;
                    max-width: 100%;
                    padding: 5px 20px 5px 10px;
                    border: none;
                    background-color: transparent;
                    -webkit-appearance: none;
                     -moz-appearance: none;
                          appearance: none;
                    &:active,
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }

                select option {
                    background: $background;
                    border: none;
                }

                &:after {
                    content: " ";
                    position: absolute;
                    top: 50%;
                    margin-top: -2px;
                    right: 8px;
                    width: 0; 
                    height: 0; 
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid $button;
                }
            }
        }

        &__add-action {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .price {
                font-size: 1.3rem;
                font-weight: 400;
                margin-bottom: 10px;
            }

            a {
                width: 100%;
                margin-top: 10px;

                button {
                    background: $background;
                    border: 2px solid $button;
                    color: $button;
                    padding: 6px 15px;
                }
            }

            button {
                width: 100%;
                font-size: 0.8rem;
                padding: 8px 15px;
            }
        }
    }
}

@media screen and (min-width: 350px) {
    .single-product-shop-view {
        width: 100%;
        margin-bottom: 15px;

        &__title {
            font-size: 1.3rem;
            margin-bottom: 9px;
        }

        &__description {
            font-size: 1.1rem;
            margin-bottom: 12px;
        }

        &__diet-length-action {

            h4 {
                font-size: 1.1rem;
            }
        }

        &__add-action {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .price {
                font-size: 1.4rem;
                margin-bottom: 12px;
            }

            button {
                font-size: 0.85rem;
            }
        }
    }
}

@media screen and (min-width: 760px) {
    .single-product-shop-view {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        background: $background;
        border-radius: $border-radius;
        padding: 8px;

        &__img {
            width: 20%;
            border-radius: 0;
        }

        &__content-wrapper {
            width: 100%;
            text-align: left;
            align-items: flex-start;
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
            padding: 0px 0px 0px 5px;
            border-bottom-left-radius: 0;
        }

        &__title {
            font-size: 1.2rem;
            margin-bottom: 10px;
            display: block;
        }

        &__description {
            font-size: 1rem;
            font-weight: 300;
            margin-bottom: 10px;
        }

        &__actions-wrapper {
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
        }

        &__diet-length-action {
            margin-bottom: 0;
        }

        &__add-action {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .price {
                font-size: 1.2rem;
                font-weight: 400;
                margin-bottom: 0;
                margin-right: 10px;
            }

            a {
                width: auto;
                margin-top: 0;
                margin-left: 5px;

                button {
                    background: $background;
                    border: 2px solid $button;
                    color: $button;
                    padding: 5px 10px;
                }
            }


            button {
                width: auto;
                font-size: 0.6rem;
                padding: 6px 10px;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .single-product-shop-view {
        &__img {
            width: 15%;
        }

        &__title {
            font-size: 1.3rem;
            margin-bottom: 15px;
        }

        &__description {
            font-size: 1.15rem;
            margin-bottom: 30px;
        }

        &__diet-length-action {
            h4 {
                font-size: 1.15rem;
                margin-right: 15px;
            }

            .select-dropdown {
                select {
                    font-size: 1.15rem;
                    padding: 5px 25px 5px 12px;
                }
            }
        }

        &__add-action {
            .price {
                font-size: 1.25rem;
                margin-right: 15px;
            }

            a {
                margin-left: 10px;

                button {
                    padding: 7px 14px;
                }
            }

            button {
                font-size: 0.9rem;
                padding: 8px 14px;
            }
        }
    }
}

@media screen and (min-width: 1360px) {
    .single-product-shop-view {
        margin-bottom: 20px;
        &__img {
            width: 14%;
        }

        &__title {
            font-size: 1.5rem;
            margin-bottom: 18px;
        }

        &__description {
            font-size: 1.3rem;
            margin-bottom: 45px;
        }

        &__diet-length-action {
            h4 {
                font-size: 1.3rem;
            }

            .select-dropdown {
                select {
                    font-size: 1.3rem;
                }
            }
        }

        &__add-action {
            .price {
                font-size: 1.35rem;
                margin-right: 15px;
            }

            button {
                font-size: 1rem;
                padding: 8px 14px;
            }
        }
    }
}

@media screen and (min-width: 1800px) {
    .single-product-shop-view {
        &__img {
            width: 13%;
            margin-right: 10px;
        }

        &__title {
            font-size: 1.8rem;
            margin-bottom: 25px;
        }

        &__description {
            font-size: 1.5rem;
            margin-bottom: 60px;
        }

        &__diet-length-action {
            h4 {
                font-size: 1.5rem;
            }

            .select-dropdown {
                select {
                    font-size: 1.5rem;
                }
            }
        }

        &__add-action {
            .price {
                font-size: 1.55rem;
                margin-right: 15px;
            }

            a {
                button {
                    padding: 7px 14px;
                }
            }

            button {
                font-size: 1.1rem;
                padding: 8px 14px;
            }
        }
    }
}