/** GLOBAL STYLES LIKE CONTAINERS **/ 

.app-container {
    width: 100%;
    height: auto;
    position: relative;
}

.overflow-hidden {
    overflow: hidden;
}

@media screen and (min-width: 200px) {
    .container {
        position:  relative;

        padding: 0 30px;
        
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .sub-page-main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        .sub-page-main__background {
            position: relative;
            width: 100%;
            border-radius: $border-radius;
            background: $sub-background;
            padding: 10px;
            margin-bottom: $bottom-separator-margin-small;
        }
    }

    .row {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        color: $text;

    }
}

@media screen and (min-width: 760px) {
    .container {
        padding: 0 60px;
    }

    .sub-page-main {    
        .sub-page-main__background {
            padding: 15px;
            margin-bottom: $bottom-separator-margin;
        }
    }
}

@media screen and (min-width: 1024px) {
    .container {
        max-width: 900px;
        justify-content: flex-start;
        padding: 0;
    }

    .row {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
}

@media screen and (min-width: 1280px) {
    .container {
        max-width: 1036px;
    }
}

@media screen and (min-width: 1366px) {
    .container {
        max-width: 1120px;
    }

    .sub-page-main {    
        .sub-page-main__background {
            padding: 20px;
        }
    }
}

@media screen and (min-width: 1800px) {
    .container {
        max-width: 1366px;
    }
}