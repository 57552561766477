@media screen and (min-width: 200px) {
    .social-media-list {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-bottom: 20px;

        &__item {
            padding: 5px;
            img {
                padding: 0;
                margin: 0;
                background: none;
                width: 25px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .social-media-list {
        &__item {
            padding: 8px;
            img {
                width: 40px;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .social-media-list {
        padding-top: 10px;
        &__item {
            padding: 10px;
            padding-top: 0;
            img {
                width: 35px;
            }
        }
    }
}