@media screen and (min-width: 200px) {
    .welcome-component {
        width: 100%;
        height: auto;

        display: flex;
        flex-direction: column;
        align-items: center;

        margin-bottom: $bottom-separator-margin;

        .welcome-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            text-align: center;
            color: $text;
            font-weight: 600;
            line-height: 2.1rem;

            margin-bottom: 20px;

            &__hello {
                font-size: 2.3rem;
                margin-bottom: 8px;
            }

            .name::after {
                content: ' Natalia';
                color: $button;
            }

            &__big-text {
                font-size: 1.5rem;
                margin-bottom: 12px;
            }

            &__small-text {
                line-height: 1.3rem;
                margin-bottom: 20px;
            }
        }

        .image-wrapper {
            width: 100%;
            // height: 300px;
            display: flex;
            justify-content: center;
            align-items: flex-end;

            background-color: $modal-background;
            border-radius: $border-radius;

            img {
                width: 100%;
                object-position: 0 10px;
            }
        }
    }
}

@media screen and (min-width: 760px) {
    .welcome-component {
        .welcome-wrapper {
            margin-bottom: 30px;

            &__hello {
                font-size: 3rem;
                margin-bottom: 20px;
            }

            &__big-text {
                font-size: 2rem;
                margin-bottom: 25px;
            }

            &__small-text {
                line-height: 1.8rem;
                font-size: 1.6rem;

                margin-right: 30px;
                margin-left: 30px;

                margin-bottom: 25px;
            }
        }

        .image-wrapper {
            width: 85%;
        }
    }
}

@media screen and (min-width: 1024px) {
    .welcome-component {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        margin-top: 80px;

        .welcome-wrapper {
            height: 100%;
            justify-content: space-between;
            align-items: flex-start;

            text-align: start;
            line-height: 2.2rem;

            margin-bottom: 0;

            &__hello {
                font-size: 2.8rem;
                margin-bottom: 10px;
            }

            &__big-text {
                font-size: 2rem;
                margin-bottom: 30px;
            }

            &__small-text {
                line-height: 1.7rem;
                font-size: 1.6rem;
                max-width: 90%;
                margin: 0;
                margin-bottom: 30px;
            }
        }

        .image-wrapper {
            width: 90%;
            max-height: 100%;

            img {
                object-fit: cover;
                max-height: 100%;
            }
        }
    }
}

@media screen and (min-width: 1280px) {
    .welcome-component {
        padding-bottom: 20px;
        .welcome-wrapper {
            &__hello {
                font-size: 3.2rem;
                margin-bottom: 10px;
            }

            &__big-text {
                font-size: 2.4rem;
                line-height: 2.9rem;
            }

            &__small-text {
                line-height: 2rem;
                font-size: 1.8rem;
                max-width: 90%;
                margin: 0;
                margin-bottom: 35px;
            }
        }
    }
}

@media screen and (min-width: 1366px) {
    .welcome-component {
        padding-bottom: 20px;
        .welcome-wrapper {
            &__hello {
                font-size: 3.2rem;
                margin-bottom: 15px;
            }

            &__big-text {
                font-size: 2.4rem;
                line-height: 2.9rem;
            }

            &__small-text {
                line-height: 2rem;
                font-size: 1.8rem;
                max-width: 90%;
                margin: 0;
                margin-bottom: 35px;
            }
        }

        .image-wrapper {
            max-height: 500px;
        }
    }
}

@media screen and (min-width: 1800px) {
    .welcome-component {
        .welcome-wrapper {
            &__hello {
                font-size: 4.1rem;
                margin-bottom: 20px;
            }

            &__big-text {
                font-size: 3rem;
                line-height: 3.7rem;
                margin-bottom: 40px;
            }

            &__small-text {
                line-height: 2.4rem;
                font-size: 2.2rem;
                margin-bottom: 40px;
            }
        }

        .image-wrapper {
            max-height: 570px;

            img {
                object-position: 0 65px;
            }
        }
    }
}