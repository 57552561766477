@media screen and (min-width: 200px) {
    .product-added {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $text;
    
        &__title {
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 15px;
        }
    
        &__product-name {
            font-size: 0.9rem;
            font-weight: 300;
            margin-bottom: 25px;
            text-align: center;
        }
    
        &__buttons-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 20px;
    
            button {
                width: 100%;
                margin: 5px;
                margin-top: 0;
                font-size: 0.5rem;
                padding: 6px 8px;
                min-width: 100px;
            }
        }
    }
}

@media screen and (min-width: 360px) {
    .product-added {    
        &__title {
            font-size: 1.1rem;
            font-weight: 600;
            margin-bottom: 20px;
        }
    
        &__product-name {
            font-size: 1rem;
            font-weight: 300;
            margin-bottom: 25px;
        }
    
        &__buttons-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
    
            button {
                margin: 10px;
                margin-top: 0;
                font-size: 0.6rem;
                padding: 6px 10px;
                min-width: 120px;
            }
        }
    }
}

@media screen and (min-width: 760px) {
    .product-added {    
        &__title {
            text-align: center;
            font-size: 1.45rem;
            font-weight: 600;
            margin: 0 20px;
            margin-bottom: 20px;
        }
    
        &__product-name {
            text-align: center;
            font-size: 1.25rem;
            margin-bottom: 30px;
        }
    
        &__buttons-wrapper {
            button {
                font-size: 1rem;
                padding: 8px 12px;
                min-width: 180px;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .product-added {    
        &__title {
            text-align: center;
            font-size: 1.6rem;
            font-weight: 600;
            margin: 0 25px;
            margin-bottom: 20px;
        }
    
        &__product-name {
            text-align: center;
            font-size: 1.3rem;
            margin-bottom: 30px;
        }
    
        &__buttons-wrapper {
            button {
                font-size: 1rem;
                padding: 8px 12px;
                min-width: 200px;
            }
        }
    }
}