@media screen and (min-width: 200px) {
    .recipes-page {
        width: 100%;
        height: auto;

        display: grid;
        grid-gap: 20px;
        // grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}

@media screen and (min-width: 700px) {
    .recipes-page {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}