@media screen and (min-width: 200px) {
    .hamburger-wrapper {
        width: 45px;
        height: 40px;
        background: transparent;

        padding: 5px;
    
        position: absolute;
        top: 10px;
        right: 10px;

        cursor: pointer;

        .hamburger {
            width: 100%;
            height: 100%;

            position: relative;

            &__inner {
                position: absolute;
                inset: 0;
                margin-top: auto;
                margin-bottom: auto;

                display: block;

                width: 100%;
                height: 3px;

                background: $button;
            }

            &__inner::after,
            &__inner::before {
                position: absolute;

                content: "";
                display: block;

                width: 100%;
                height: 3px;

                background-color: $button;

                transform: rotate(0deg);
                transition: all .3s ease-in;
            }

            &__inner::after {
                top: -10px;
            }

            &__inner::before {
                bottom: -10px;
            }
        }

        .menu-visible {
            .hamburger__inner {
                background: transparent;
            }
            .hamburger__inner::after {
                inset: 0;
                margin-top: auto;
                margin-bottom: auto;

                transform: rotate(45deg);
            }
            .hamburger__inner::before {
                inset: 0;
                margin-top: auto;
                margin-bottom: auto;

                transform: rotate(-45deg);
            }
        }
    }

    .hamburger-z-index {
        z-index: 101;
    }
}

@media screen and (min-width: 1024px) {
    .hamburger-wrapper {
        position: static;
        margin-top: 15px;
        margin-left: 25px;
    }
}