.backdrop {
    width: 100%;
    height: 100vh;

    position: fixed;
    top: 0;

    background: $modal-backdrop;
    opacity: 0;
    z-index: -1;
}

.backdrop.visible {
    animation: backdropShow ease-in-out .3s;
    opacity: 1;
    z-index: 99;
}

@keyframes backdropShow {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}