@media screen and (min-width: 200px) {
    .pair-new-cooperation {
        width: 100%;

        .interview-wrapper {
            width: 100%;

            &__title {
                color: $text;
                width: 100%;
                text-align: center;
                font-size: 1rem;
                margin-bottom: 5px;
            }

            &__subtitle {
                color: $text;
                width: 100%;
                text-align: center;
                font-size: 0.7rem;
                margin-bottom: 20px;
            }
        }
    }
}

@media screen and (min-width: 760px) {
    .pair-new-cooperation {
        .interview-wrapper {
            &__title {
                font-size: 1.3rem;
                margin-bottom: 8px;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .pair-new-cooperation {
        .interview-wrapper {
            &__subtitle {
                margin-bottom: 25px;
            }
        }
    }
}

@media screen and (min-width: 1800px) {
    .pair-new-cooperation {
        .interview-wrapper {
            &__subtitle {
                font-size: 1rem;
            }
        }
    }
}