/** WEBSITE COLORS VARIABLES **/

$background: #ffffff;
$sub-background: #f9f3f3;

$inactive-tab: #dcdcdc;

$modal-background: #f7d9d9; 
$modal-backdrop: rgba(0, 0, 0, 0.75);

$text: #8f2773;
$sub-text: #ffffff;

$button: #f25287;