@media screen and (min-width: 200px) {
    .recipe-view {
        width: 100%;
        height: 100%;

        background: $background;
        border-radius: $border-radius;

        padding: 10px;

        .recipe {
            &__image-wrapper {
                width: 100%;
                position: relative;
                margin-bottom: 15px;
            }

            &__main-img {
                position: absolute;
                width: 100%;
                top: 0;
                height: 100%;

                object-fit: cover;

                border-radius: $border-radius;
            }

            &__image-wrapper::before {
                content: "";
                display: block;
                padding-top: 100%;
            }

            &__title {
                font-size: 1.3rem;
                line-height: 1.5rem;
                margin-bottom: 10px;
                width: 100%;
                font-weight: 600;
                text-align: center;
            }

            &__description {
                width: 100%;
                text-align: center;
                font-size: 1.1rem;
                line-height: 1.2;
            }

            .bottom-desc {
                margin-top: 10px;
            }

            &__ingredients-wrapper {
                width: 100%;
                margin-top: 10px;
                padding: 0 5px;
            }

            &__ingredients-title {
                width: 100%;
                text-align: left;
                font-size: 1.1rem;
                font-weight: 600;
                margin: 10px 0;
            }

            &__ingredients {
                width: 100%;
                text-align: left;
                list-style: disc;
                list-style-position: inside;

                .ingredient {
                    margin-bottom: 8px;
                    padding-left: 2em;
                    text-indent: -1.4em;
                }
            }
        }

        .recipe:last-of-type {
            .recipe__main-img {
                margin-top: 15px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .recipe-view {
        padding: 15px;

        .recipe {
            &__description {
                font-size: 1.3rem;
                line-height: 1.5;
                margin: 15px 0;
            }

            &__title {
                font-size: 1.8rem;
                margin: 15px 0 5px 0;
            }

            &__ingredients-wrapper {
                padding-left: 20px;
            }

            &__ingredients-title {
                font-size: 1.5rem;
                margin: 15px 0;
            }

            &__ingredients {
                font-size: 1.3rem;
                padding-left: 15px;

                .ingredient {
                    margin-bottom: 10px;
                }
            }
        }

        .recipe:last-of-type {
            .recipe__main-img {
                margin-top: 20px;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .recipe-view {
        padding: 15px;

        .recipe {
            flex-direction: column;
            padding-bottom: 5px;

            &__image-wrapper {
                padding-top: 0;
                height: 550px;
            }

            .bottom-image {
                height: auto;
            }

            &__main-img {
                margin-bottom: 15px;
            }

            &__title {
                font-size: 1.9rem;
                margin: 5px 0 20px 0;
            }

            &__description {
                font-size: 1.2rem;
                line-height: 1.4;
                margin: 0;
                margin-bottom: 5px;
            }

            &__ingredients-wrapper {
                width: auto;
                margin-bottom: 5px;
            }

            &__ingredients-title {
                font-size: 1.4rem;
                margin: 15px 0;
            }

            &__ingredients {
                font-size: 1.2rem;
                padding-left: 15px;

                .ingredient {
                    margin-bottom: 10px;
                }
            }
        }

        .recipe:last-of-type {
            .recipe__description {
                margin-bottom: 0;
            }
            .recipe__main-img {
                margin-top: 20px;
            }
        }
    }
}

@media screen and (min-width: 1366px) {
    .recipe-view {
        padding: 20px;

        .recipe {
            &__main-img {
                margin-bottom: 20px;
            }

            &__title {
                font-size: 2.1rem;
                margin: 10px 0 20px 0;
            }

            &__description {
                font-size: 1.4rem;
                line-height: 1.6;
                margin: 0;
            }

            &__ingredients-wrapper {
                width: auto;
                margin-bottom: 0px;
            }

            &__ingredients-title {
                font-size: 1.6rem;
                margin: 20px 0;
            }

            &__ingredients {
                font-size: 1.4rem;
                padding-left: 20px;

                .ingredient {
                    margin-bottom: 15px;
                }
            }
        }
    }
}

@media screen and (min-width: 1800px) {
    .recipe-view {
        .recipe {
            &__image-wrapper {
                height: 650px;
            }

            &__description {
                font-size: 1.6rem;
                line-height: 1.6;
            }


            &__title {
                font-size: 2.3rem;
            }

            &__ingredients-title {
                font-size: 1.8rem;
            }

            &__ingredients {
                font-size: 1.6rem;

            }
        }
    }
}