@media screen and (min-width: 200px) {
    .products {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    
    .empty-basket {
        padding: 20px 10px;
        h2 {
            font-size: 1rem;
            text-align: center;
        }
    }
    
    .summary {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;

        a {
            width: 100%;
        }
        
        button {
            width: 100%;
        }
    }
    
    .heading {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid $button;
        padding: 4px 8px;
        font-size: 0.8rem;
    }
    
    .body {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        background: $background;
        border-radius: $border-radius;
        margin-top: 8px;
    }
    
    .product-el {
        width: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 5px;
        font-size: 0.8rem;
    
        img {
            width: 70px;
            height: 70px;
            object-fit: cover;
            border-radius: $border-radius;
        }
    
        p {
            display: none;
        }
    }
    
    .length-el {
        width: 60px;
        margin-right: 10px;
        display: flex;
        font-size: 0.8rem;
        justify-content: center;
        align-items: center;
    }
    
    .price-el {
        width: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        margin-right: 10px;
    }
    
    .actions-el {
        font-size: 0.8rem;
        width: 55px;
        text-align: end;
    
        a {
            width: 100%;
        }
        
        
        button {
            width: 100%;
            padding: 5px 12px;
            line-height: 0.6rem;
            font-size: 0.5rem;
            font-weight: 600;
        }
    }
    
    .delete-el {
        font-size: 0.8rem;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    
        .delete-wrapper {
            width: 20px;
            height: 20px;
            background: $modal-background;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;
            cursor: pointer;
    
            .delete,
            .delete::after {
                content: "";
                display: block;
                width: 100%;
                height: 2px;
                background: $text;
                transform: rotate(45deg);
            }
    
            .delete::after {
                transform: rotate(-90deg);
            }
        }
    }
    
    .summary-info-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background: #f6d9d8;
        border: 2px solid $button;
        margin-bottom: 10px;
    
        &__title,
        &__price {
            width: 100%;
            font-size: 1.5rem;
            font-weight: 600;
        }
    
        &__title {
            color: $sub-text;
            text-align: left;
        }
    
        &__price {
            text-align: right;
        }
    }   
}

@media screen and (min-width: 360px) {
    .product-el {
        width: 90px;
    
        img {
            width: 90px;
            height: 90px;
        }
    }
}

@media screen and (min-width: 768px) {   
    .empty-basket {
        padding: 20px 10px 10px 10px;
        h2 {
            font-size: 2rem;
            min-width: 350px;
        }
    }
    
    .heading {
        padding: 10px 8px;
        font-size: 1.1rem;
    }
    
    .body {
        margin-top: 10px;
        height: 120px;
    }
    
    .product-el {
        width: 200px;
        font-size: 1.1rem;
    
        img {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: $border-radius;
            margin-right: 10px;
        }
    
        p {
            display: block;
            text-align: center;
            font-size: 0.9rem;

        }
    }
    
    .length-el {
        width: 80px;
        font-size: 1.1rem;
    }
    
    .price-el {
        width: 75px;
        font-size: 1.1rem;
    }
    
    .actions-el {
        font-size: 1.1rem;
        width: 80px;
        
        
        button {
            font-size: 0.7rem;
        }
    }
    
    .delete-el {
        font-size: 1.1rem;
        width: 60px;
    
        .delete-wrapper {
            width: 30px;
            height: 30px;
        }
    } 
}

@media screen and (min-width: 1024px) {
    .products {
        width: 70%;
        margin-right: 10px;
    }
    
    .empty-basket {
        padding: 40px 20px;
        h2 {
            font-size: 2rem;
        }
    }
    
    .summary {
        margin-left: 10px;
        width: 30%;
        margin-top: 0;

        button {
            font-size: 1rem;
        }
    }

    .body {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        background: $background;
        border-radius: $border-radius;
        margin-top: 10px;
    }
    
    .product-el {
        width: 200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 10px;
    
        img {
            width: 82px;
            height: 82px;
            object-fit: cover;
            margin-right: 15px;
            border-radius: $border-radius;
        }
    
        p {
            font-size: 0.9rem;
        }
    }
    
    .actions-el {
        width: 100px;
        text-align: end;    
        
        button {
            padding: 8px;
            font-size: 0.9rem;
        }
    }
    
    .delete-el {  
        .delete-wrapper {
            width: 25px;
            height: 25px;
        }
    }
    
    .summary-info-wrapper {
        background: #f6d9d8;
    
        &__title,
        &__price {
            width: 100%;
            font-size: 1.5rem;
            font-weight: 600;
        }
    
        &__title {
            color: $sub-text;
            text-align: left;
        }
    
        &__price {
            text-align: right;
        }
    }
    
}