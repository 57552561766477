@media screen and (min-width: 200px) {
    .button {
        height: auto;
    
        display: flex;
        justify-content: center;
        align-items: center;
    
        padding: 10px 18px;
    
        font-size: .9rem;
        font-weight: 600;
        line-height: 17px;
    
        color: $sub-text;
        background: $button;
        border-radius: $border-radius;
    
        border: none;
        user-select: none;
        cursor: pointer;
    }

    .button.small {
        padding: 4px 22px;
        font-size: 0.85rem;
    }
}

@media screen and (min-width: 400px) {
    .button.small {
        padding: 8px 22px;
        font-size: 1.2rem;
    }
}

@media screen and (min-width: 768px) {
    .button {
        padding: 16px 24px;
        font-size: 1.4rem;
    }
}

@media screen and (min-width: 1024px) {
    .button.small {
        padding: 6px 20px;
        font-size: 0.8rem;
    }
}

@media screen and (min-width: 1800px) {
    .button.small {
        padding: 8px 22px;
        font-size: 1.2rem;
    }
}



.button.medium {
    font-size: 1.1rem;
}

.button.big {
    font-size: 1.5rem;
    padding: 4px 18px;
}