@media screen and (min-width: 200px) {
    .interview {
        padding: 10px 5px;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

@media screen and (min-width: 1024px) {
    .interview {
        margin: 0 20px;
    }
}