@media screen and (min-width: 200px) {
    .account-panel {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        color: $text;
        padding-bottom: 0px;
        max-height: 90vh;
        min-width: 245px;
        padding-bottom: 5px;
        
        &__title {
            font-size: 1.1rem;
            margin-bottom: 10px;
            font-weight: 600;
        }

        .react-tabs {
            &__tab-list {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
    
            &__tab {
                width: auto;
                padding: 8px 12px;
                background: $inactive-tab;
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
                margin-right: 3px;
                text-align: center;
                cursor: pointer;
                font-size: 0.7rem;
                vertical-align: middle;
                font-weight: 600;
                color: $text;
                opacity: 0.5;
                transition: all .1s ease-in;
            }

            li:last-of-type {
                margin-right: 0;
            }
    
            &__tab--selected {
                background: $sub-background;
                opacity: 1;
                transition: all .1s ease-in;
            }
    
            &__tab-panel {
                background: $sub-background;
                border-radius: $border-radius;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
    
            .tab-content-wrapper {
                padding: 10px 10px 0 10px;
                min-height: 300px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

				.diet-urls {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					width: 100%;
					text-align: center;

					li {
						margin-bottom: 10px;
						width: 100%;
						border-bottom: 2px solid $modal-background;
						padding-bottom: 5px;

						a {
							width: 100%;
						}

						p {
							margin-top: 10px;
							font-size: 0.7rem;
							word-break: break-all
						};
					}
				}

                .account-informations-box,
                .account-actions-box {
                    width: 100%;
                    margin-bottom: 15px;
                    &__title {
                        font-size: 1rem;
                        text-align: left;
                        font-weight: 600;
                        margin-bottom: 8px;
                    }

                    &__data {
                        padding: 5px 10px;

                        h4 {
                            font-weight: 300;
                            font-size: 0.7rem;
                            margin-bottom: 5px;
                        }

                        p {
                            font-weight: 600;
                            margin-left: 5px;
                            margin-top: 10px;
                            font-size: 0.9rem;
                        }
                    }
                }

                .account-actions-box {
                    .action-form {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    .input-wrapper {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        position: relative;
            
                        label {
                            font-size: 0.75rem;
                            font-weight: 600;
                            color: $text;
                            text-align: center;
                            margin: 5px 0 5px 0;
                        }
            
                        input {
                            outline: none;
                            border: none;
                            background: $background;
                            border-radius: $border-radius;
                            padding: 8px 12px;
                            color: $text;
                            height: 35px;
                            margin-bottom: 10px;
                        }

                    }
                    
                    button {
                        width: 100%;
                    }

                    .delete-account-error {
                        font-size: 0.7rem;
                        color: $button;
                        width: 100%;
                        text-align: center;
                        margin-top: 8px;
                    }
                }

                .empty-diets-info {
                    text-align: center;
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }    
        }
    }
}

@media screen and (min-width: 768px) {
    .account-panel {
        padding-bottom: 10px;
        max-height: 90vh;
        
        &__title {
            font-size: 1.3rem;
            margin-bottom: 15px;
            font-weight: 600;
        }

        .react-tabs {    
            &__tab-list {
                justify-content: flex-start;
            }

            &__tab-panel {
                border-top-right-radius: $border-radius;
            }

            &__tab {
                font-size: 0.9rem;
                font-weight: 600;
            }
    
            .tab-content-wrapper {
                padding: 15px 15px 0 15px;
                min-width: 380px;
                margin-bottom: 10px;

                .account-informations-box,
                .account-actions-box {

                    &__title {
                        font-size: 1.1rem;
                    }
                }

                .account-actions-box {
                    .input-wrapper {
                        label {
                            font-size: 0.85rem;
                            margin: 5px 0 10px 0;
                        }
            
                        input {
                            margin-bottom: 15px;
                        }

                    }
                    
                    button {
                        width: 100%;
                        height: 35px;
                        font-size: 1rem;
                    }

                    .delete-account-error {
                        font-size: 0.85rem;
                        margin-top: 12px;
                    }
                }
            }    
        }
    }
}

@media screen and (min-width: 1024px) {
    .account-panel {
        &__title {
            font-size: 1.6rem;
            margin-bottom: 20px;
        }
    }
}