@media screen and (min-width: 200px) {
    .modal-wrapper {
        position: absolute;
        inset: 0;
        margin-left: auto;
        margin-right: auto;
    
        height: 100vh;
        
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .modal {
        max-width: 280px;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        background: $modal-background;
        border-radius: $border-radius;
        z-index: 101;

        padding: 20px 15px 10px;
    
        display: flex;
        justify-content: center;
        align-items: center;
    
        animation: backdropShow ease-in-out .3s;
        opacity: 1;
        
        .close-modal-wrapper {
            width: 20px;
            height: 20px;
    
            position: absolute;
            top: 20px;
            right: 10px;

            z-index: 100;
    
            cursor: pointer;
    
            .close-modal,
            .close-modal::after {
                content: "";
                display: block;
                width: 100%;
                height: 4px;
                background: $button
            }
    
            .close-modal {
                transform: rotate(45deg);
            }
    
            .close-modal::after {
                transform: rotate(-90deg);
            }
        }
    }
}

@media screen and (min-width: 360px) {   
    .modal {
        max-width: 340px;
        padding: 20px 15px 10px;

        .close-modal-wrapper {
            width: 23px;
            height: 23px;

            top: 22px;
            right: 10px;
        }
    }
}

@media screen and (min-width: 760px) {   
    .modal {
        max-width: 440px;
        padding: 20px 30px 10px;

        .close-modal-wrapper {
            width: 30px;
            height: 30px;

            top: 26px;
            right: 12px;
        }
    }
}

@media screen and (min-width: 1024px) {   
    .modal {
        max-width: 100%;
        padding: 20px 30px 10px;

        .close-modal-wrapper {
            width: 30px;
            height: 30px;

            top: 26px;
            right: 12px;
        }
    }
}




@keyframes backdropShow {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}