@media screen and (min-width: 200px) {
    .footer-component-wrapper {
        width: 100%;
        height: auto;
        
        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: $bottom-separator-margin;
        background: $sub-background;
        padding-bottom: $global-padding;

        .footer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .copyright {
                font-size: 0.85rem;
                color: $text;

                a {
                    color: $text;
                    text-decoration: underline;
                }
            }
        }

        .footer-content {
            width: 100%;
            height: auto;
            
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            color: $text;
            text-align: center;

            padding-top: $global-padding;

            &__nav,
            &__rules {
                margin-bottom: 20px;
                h3 {
                    font-size: 1.5rem;
                    font-weight: 600;
                    margin-bottom: 15px;
                }

                ul {
                    li {
                        cursor: pointer;
                        font-size: 1.15rem;
                        margin-bottom: 8px;

                        a {
                            color: $text;
                            text-decoration: none;
                        }
                    }
                }
            }

            .content-wrapper:nth-of-type(1) {
                order: 2;
            }

            .content-wrapper:nth-of-type(3) {
                order: 3;
            }


            &__social-media {
                margin-top: -70px;
                .logo {
                    width: 185px;
                    background: $modal-background;
                    padding: $global-padding / 2;
                    border-radius: $border-radius;
                    margin-bottom: 20px;
                }
                
            }
            
        }
    }
}

@media screen and (min-width: 768px) {
    .footer-component-wrapper {
        .footer {
            .copyright {
                font-size: 1.2rem;
            }
        }

        .footer-content {
            &__nav,
            &__rules {
                margin-bottom: 25px;
                h3 {
                    font-size: 2.2rem;
                    margin-bottom: 20px;
                }

                ul {
                    li {
                        font-size: 1.6rem;
                        margin-bottom: 8px;
                    }
                }
            }

            &__social-media {
                .logo {
                    width: 250px;
                }
            }
            
        }
    }
}

@media screen and (min-width: 1024px) {
    .footer-component-wrapper {
        .footer {
            .copyright {
                font-size: 1rem;
            }
        }

        .footer-content {
            text-align: left;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 20px;
            &__nav,
            &__rules {
                width: auto;
                padding-top: $global-padding;
                margin-bottom: 25px;
                h3 {
                    font-size: 1.9rem;
                    margin-bottom: 20px;
                }

                ul {
                    li {
                        font-size: 1.1rem;
                        margin-bottom: 8px;
                    }
                }
            }

            .content-wrapper:nth-of-type(1) {
                order: 1;
            }

            .content-wrapper:nth-of-type(2) {
                order: 2;
            }

            &__social-media {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .logo {
                    width: 180px;
                }
            }

            .content-wrapper{
                width: 30%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            
        }
    }
}

@media screen and (min-width: 1800px) {
    .footer-component-wrapper {
        margin-top: $bottom-separator-margin * 2;
        .footer {
            .copyright {
                font-size: 1.2rem;
            }
        }

        .footer-content {
            &__nav,
            &__rules {
                h3 {
                    font-size: 2.3rem;
                    margin-bottom: 25px;
                }

                ul {
                    li {
                        font-size: 1.4rem;
                        margin-bottom: 10px;
                    }
                }
            }

            &__social-media {
                .logo {
                    width: 220px;
                }
            }            
        }
    }
}