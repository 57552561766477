@media screen and (min-width: 200px) {
    .page-title-box {
        width: 100%;
        display: flex;
        justify-content: flex-start;

        .title {
            color: $text;
            font-size: 1rem;
            font-weight: 600;
            padding: 5px 10px;
            background: $sub-background;
            border-radius: $border-radius;
            margin-bottom: 10px;
        }
    }
}

@media screen and (min-width: 768px) {
    .page-title-box {
        .title {
            font-size: 1.1rem;
            padding: 8px 13px;
        }
    }
}

@media screen and (min-width: 1024px) {
    .page-title-box {
        .title {
            font-size: 1.1rem;
            padding: 8px 13px;
        }
    }
}

@media screen and (min-width: 1280px) {
    .page-title-box {
        .title {
            font-size: 1.2rem;
        }
    }
}