@media screen and (min-width: 200px) {
    .order-pre-confirmation {
        padding: 0px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $text;
    
        &__title {
            min-width: 200px;
            text-align: center;
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 15px;
        }

        form {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;

            .input-wrapper {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 8px;
                position: relative;
    
                label {
                    font-size: 0.75rem;
                    font-weight: 600;
                    color: $text;
                }
    
                input, select, textarea {
                    outline: none;
                    border: none;
                    background: $background;
                    border-radius: $border-radius;
                    padding: 8px 12px;
                    margin: 5px;
                    color: $text;
                    height: 35px;
                }
    
                textarea {
                    resize: none;
                    height: 80px;
                }
            }

            .input-wrapper .required-field+span::after {
                content: "*";
                font-size: 1.5rem;
                color: $button;
                position: absolute;
                right: 20px;
                top: 50%;
            }

            .error-message {
                font-size: 0.7rem;
                color: $button;
                position: absolute;
                right: 0;
                top: -1px;
                text-align: center;
            }
        }

        .realization-error {
            margin-bottom: 10px;
            min-width: 250px;
        }

        &__payment-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .order-info {
                width: 100%;
                text-align: center;
                font-size: 0.8rem;
                font-weight: 500;
                line-height: 0.9rem;
                margin-bottom: 20px;

            }

            .data-wrapper {
                width: 100%;
                min-width: 240px;
                padding: 10px 10px 5px 10px;
                border: 2px solid $button;
                border-radius: $border-radius;
                margin-bottom: 10px;
            }

            .data-box {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                margin-bottom: 5px;

                .data-box-title {
                    font-size: 0.9rem;
                    font-weight: 300;
                }

                .data-box-value {
                    font-size: 0.75rem;
                    font-weight: 600;
                    padding: 5px;
                }
            }
        }

        &__info {
            text-align: center;
            font-size: 0.75rem;
            margin-bottom: 15px;
            line-height: 0.9rem;
        }

        &__rules-info {
            text-align: center;
            font-size: 0.7rem;
            line-height: 0.8rem;

            span {
                color: $button;
                text-decoration: underline;
                cursor: pointer;
            }

        }

        .account-question-box {
            margin: -5px 5px 13px 5px;
            .checkbox {
                width: 100%;
                margin: auto;
                position: relative;
                display: block;
            }
            
            .checkbox input[type="checkbox"] {
                width: auto;
                opacity: 0.00000001;
                position: absolute;
                left: 0;
                margin-left: -20px;
            }

            .checkbox label {
                position: relative;
                font-size: 0.7rem;
                display: flex;
                align-items: center;
            }

            .checkbox label:before {
                content: '';
                position: absolute;
                left: 0;
                top: 2px;
                margin: 4px 4px 4px 0;
                width: 22px;
                height: 22px;
                transition: transform 0.28s ease;
                border-radius: $border-radius;
                border: 2px solid $background;
            }
            .checkbox label:after {
                content: '';
                display: block;
                width: 10px;
                height: 5px;
                border-bottom: 2px solid $button;
                border-left: 2px solid $button;
                -webkit-transform: rotate(-45deg) scale(0);
                transform: rotate(-45deg) scale(0);
                transition: transform ease 0.25s;
                will-change: transform;
                position: absolute;
                top: 14px;
                left: 6px;
            }
            .checkbox input[type="checkbox"]:checked ~ label::before {
                color: $button
            }
            
            .checkbox input[type="checkbox"]:checked ~ label::after {
                -webkit-transform: rotate(-45deg) scale(1);
                transform: rotate(-45deg) scale(1);
            }
            
            .checkbox label {
                min-height: 34px;
                padding-left: 35px;
                margin-bottom: 0;
                font-weight: normal;
                cursor: pointer;
            }

            .checkbox label span {
                position: absolute;
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
            }

            .checkbox input[type="checkbox"]:focus + label::before {
                outline: 0;
            }
        
        }

        &__buttons-wrapper {
            padding: 15px 0 10px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                width: 50%;
                font-size: 0.8rem;
                margin: 5px;
            }
        }
    }
}

@media screen and (min-width: 350px) {
    .order-pre-confirmation {
    
        &__title {
            min-width: 200px;
            font-size: 1rem;
        }

        &__payment-info {
            .order-info {
                min-width: 280px;
                font-size: 0.85rem;
                line-height: 1rem;
            }

            .data-box {
                margin-bottom: 5px;

                .data-box-title {
                    font-size: 1rem;
                    font-weight: 300;
                }

                .data-box-value {
                    font-size: 0.8rem;
                }
            }
        }

        &__info {
            text-align: center;
            font-size: 0.8rem;
            margin-bottom: 15px;
            line-height: 0.9rem;
        }

        &__rules-info {
            text-align: center;
            font-size: 0.8rem;
            line-height: 0.9rem;
        }


        &__buttons-wrapper {
            padding: 15px 0 10px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                width: 50%;
                font-size: 0.8rem;
                margin: 5px;
            }
        }
    }
}

@media screen and (min-width: 760px) {
    .order-pre-confirmation {
    
        &__title {
            min-width: 200px;
            font-size: 1.1rem;
        }

        .realization-error {
            margin-bottom: 20px;
        }

        &__payment-info {

            
            .order-info {
                min-width: 400px;
                font-size: 1.1rem;
                line-height: 1.3rem;
            }

            .data-box {
                margin-bottom: 5px;

                .data-box-title {
                    font-size: 1.1rem;
                    font-weight: 300;
                }

                .data-box-value {
                    font-size: 1rem;
                }
            }
        }

        &__info {
            min-width: 400px;
            text-align: center;
            font-size: 1rem;
            line-height: 1.3rem;
        }

        &__buttons-wrapper {
            padding: 15px 0 10px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                width: 50%;
                font-size: 0.8rem;
                margin: 5px;
                padding: 10px 24px;
            }
        }

        .account-question-box {
            margin-bottom: 13px;
            .checkbox {
                width: 100%;
                margin: auto;
                position: relative;
                display: block;
            }
            
            .checkbox input[type="checkbox"] {
                width: auto;
                opacity: 0.00000001;
                position: absolute;
                left: 0;
                margin-left: -20px;
            }

            .checkbox label {
                position: relative;
                font-size: 0.8rem;
                display: flex;
                align-items: center;
            }

            .checkbox label:before {
                left: 0;
                top: 0;
                width: 25px;
                height: 25px;
            }
            .checkbox label:after {
                top: 13px;
                left: 8px;
            }
            
            .checkbox label {
                min-height: 34px;
                padding-left: 35px;
                margin-bottom: 0;
                font-weight: normal;
                cursor: pointer;
            }
        
        }
    }
}

@media screen and (min-width: 1024px) {
    .order-pre-confirmation {
        width: 500px;
    
        &__title {
            min-width: 200px;
            font-size: 1.1rem;
        }

        .realization-error {
            margin-bottom: 20px;
        }

        // form {
        //     width: 50%;
        // }
    }
}

@media screen and (min-width: 1800px) {
    .order-pre-confirmation {
    
        &__title {
            min-width: 200px;
            font-size: 1.6rem;
        }

        &__payment-info {

            .data-wrapper {
                margin-bottom: 20px;
            }
            
            .order-info {
                min-width: 400px;
                font-size: 1.55rem;
                line-height: 1.7rem;
            }

            .data-box {
                margin-bottom: 10px;

                .data-box-title {
                    font-size: 1.3rem;
                    font-weight: 300;
                }

                .data-box-value {
                    padding: 8px;
                    font-size: 1.6rem;
                }
            }
        }

        &__info {
            min-width: 400px;
            text-align: center;
            font-size: 1.2rem;
            line-height: 1.3rem;
            margin-bottom: 20px;
        }

        &__buttons-wrapper {
            padding: 15px 0 10px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                width: 50%;
                font-size: 1rem;
                margin: 10px;
                padding: 10px 34px;
            }
        }
    }
}