@media screen and (min-width: 200px) {
    .single-offer-full-view {
        width: 100%;
        background: $background;
        padding: 10px;
        color: $text;

        .img-title-box {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;
            margin-bottom: 20px;
            border-bottom: 2px solid $modal-background;

            img {
                width: 100%;
                margin-bottom: 10px;
            }

            &__title {
                font-size: 1rem;
                font-weight: 600;
                text-align: center;
                margin-bottom: 8px;
            }

            &__subtitle {
                text-align: center;
                font-size: 0.8rem;
                line-height: 0.9rem;
            }
        }

        .description-box {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            &__title {
                width: 100%;
                text-align: left;
                font-weight: 300;
                font-size: 0.8rem;
                margin-bottom: 10px;
            }

            ul {
                width: 100%;
                padding: 0 20px;
                margin-bottom: 10px;

                .description-el {
                    list-style: none;
                    text-align: left;
                    font-size: 0.8rem;
                    line-height: 1rem;
                    margin-bottom: 10px;

                    .shop-url {
                        color: $button;
                        text-decoration: underline 1px $button;
                    }
                }
            }


            ul li::before {
                content: "\2022";
                color: $button;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
            }
        }

        .example-diet-box {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &__title {
                width: 100%;
                text-align: center;
                color: $text;
                font-size: 1rem;
                margin-bottom: 10px;
            }

            &__images-wrapper {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                img {
                    width: 100%;
                    margin: 10px;
                }
            }
        }
    }
}

@media screen and (min-width: 360px) {
    .single-offer-full-view {
        width: 100%;
        background: $background;
        padding: 10px;
        color: $text;

        .img-title-box {
            &__title {
                font-size: 1.1rem;
            }

            &__subtitle {
                font-size: 0.85rem;
            }
        }

        .description-box {

            &__title {
                width: 100%;
                text-align: left;
                font-weight: 300;
                font-size: 0.9rem;
                margin-bottom: 10px;
            }

            ul {
                .description-el {
                    font-size: 0.9rem;
                    line-height: 1.2rem;
                }
            }
        }
    }
}

@media screen and (min-width: 760px) {
    .single-offer-full-view {
        padding: 20px;

        .img-title-box {
            padding-bottom: 20px;
            img {
                margin-bottom: 20px;
            }
            &__title {
                font-size: 1.3rem;
                margin-bottom: 15px;
            }

            &__subtitle {
                font-size: 1.1rem;
            }
        }

        .description-box {

            &__title {
                font-size: 1.1rem;
                margin-bottom: 15px;
            }

            ul {
                .description-el {
                    font-size: 1.1rem;
                    line-height: 1.4em;
                    margin-bottom: 15px;
                }
            }
        }

        .example-diet-box {
            &__title {
                font-size: 1.5rem;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .single-offer-full-view {
        .img-title-box {
            flex-direction: row;
            justify-content: flex-start;
            padding-bottom: 20px;

            img {
                width: 150px;
                margin-bottom: 0px;
                margin-right: 20px;
            }
            &__title {
                text-align: left;
                font-size: 1.3rem;
                margin-bottom: 15px;
            }

            &__subtitle {
                text-align: left;
                font-size: 1.1rem;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .single-offer-full-view {
        .img-title-box {
            img {
                width: 150px;
                margin-bottom: 0px;
                margin-right: 25px;
            }
            &__title {
                text-align: left;
                font-size: 1.5rem;
                margin-bottom: 15px;
            }

            &__subtitle {
                text-align: left;
                font-size: 1.2rem;
            }
        }
    }
}

@media screen and (min-width: 1800px) {
    .single-offer-full-view {
        .img-title-box {
            img {
                width: 180px;
            }
            &__title {
                font-size: 1.7rem;
            }

            &__subtitle {
                font-size: 1.3rem;
            }
        }

        .description-box {
            &__title {
                font-size: 1.3rem;
                margin-bottom: 15px;
            }

            ul {
                margin-bottom: 20px;
                .description-el {
                    font-size: 1.3rem;
                    line-height: 1.5em;
                    margin-bottom: 15px;
                }
            }
        }
    }
}