@media screen and (min-width: 200px) {
    .single-recipe {
        width: auto;
        height: auto;

        background: $background;
        border-radius: $border-radius;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .recipe-info {
            width: 100%;
            height: 50%;

            color: $text;
            text-align: center;
            padding: $global-padding;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            &__title {
                font-size: 1.4rem;
                font-weight: 600;
                margin-bottom: 10px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            &__description {
                font-size: 1.2rem;
                line-height: 1.4rem;
                font-weight: 300;
                margin-bottom: 15px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        .recipe-photo {
            width: 100%;
            height: 200px;
            object-fit: cover;
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }
}

@media screen and (min-width: 1280px) {
    .single-recipe {
        .recipe-info {
            height: 50%;

            &__title {
                font-size: 1.7rem;
                font-weight: 600;
                margin-bottom: 15px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            &__description {
                font-size: 1.4rem;
                line-height: 1.6rem;
                margin-bottom: 20px;
            }
        }

        .recipe-photo {
            height: 250px;
        }
    }
}

@media screen and (min-width: 1800px) {
    .single-recipe {
        .recipe-info {
            &__title {
                font-size: 1.9rem;
                font-weight: 600;
                margin-bottom: 15px;
            }

            &__description {
                font-size: 1.5rem;
                line-height: 1.7rem;
                margin-bottom: 20px;
            }
        }

        .recipe-photo {
            height: 300px;
        }
    }
}