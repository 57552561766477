@media screen and (min-width: 200px) {
    .contact-page-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .contact-page {
        width: 100%;

        border-radius: $border-radius;
        background: $background;
        color: $text;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding: 15px;

        &__title {
            text-align: center;
            font-size: 0.95rem;
            margin-bottom: 10px;
            font-weight: 500;
        }

        .contact-options-wrapper {
            width: 100%;
            padding-top: 10px;
            .contact-form {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                input,
                textarea {
                    color: $text;
                    width: 100%;
                    max-width: 100%;
                    padding: 5px 10px;
                    border: none;
                    border: 2px solid $sub-background;
                    border-radius: $border-radius;
                    font-size: 0.8rem;
                    outline: none;
                }

                .input-wrapper {
                    width: 100%;
                    margin-bottom: 10px;
                    position: relative;
                }

                .input-wrapper .required-field+span::after {
                    content: "*";
                    font-size: 1.5rem;
                    color: $button;
                    position: absolute;
                    right: 8px;
                    top: 8px;
                }

                ::placeholder {
                    color: $text;
                    opacity: 1;
                  }
                  
                  :-ms-input-placeholder {
                    color: $text;
                  }
                  
                  ::-ms-input-placeholder {
                    color: $text;
                  }
                
                textarea {
                    min-height: 100px;
                    resize: none;
                }

                .send-message-info {
                    height: 10px;
                    margin-bottom: 8px;
                    font-size: 0.7rem;
                    color: $button
                }

                .submit-box {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;

                    .rules-box__text {
                        text-align: center;
                        margin-bottom: 10px;
                        font-size: 0.8rem;
                    }

                    span {
                        color: $button;
                        text-decoration: underline;
                    }
                }

                button {
                    width: 100%;
                    margin-top: 5px;
                }
            }


            .contact-details {
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                margin-top: 10px;

                &__title {
                    font-size: 1.25rem;
                    font-weight: 600;
                    margin: 15px 0;
                }

                &__data {
                    font-size: 1rem;
                    font-weight: 400;
                    margin-bottom: 8px;                
                
                    a {
                        color: $text;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .contact-page {
        padding: 20px;

        &__title {
            font-size: 1.1rem;
        }

        .contact-options-wrapper {
            padding-top: 15px;
            .contact-form {
                input,
                textarea {
                    padding: 8px 12px;
                    font-size: 0.9rem;
                }

                .input-wrapper {
                    margin-bottom: 15px;
                }

                .input-wrapper .required-field+span::after {
                    right: 10px;
                    top: 10px;
                }
                
                textarea {
                    min-height: 120px;
                }

                .send-message-info {
                    height: 10px;
                    margin-bottom: 10px;
                    font-size: 0.9rem;
                    color: $button
                }

                .submit-box {
                    width: 100%;
                    flex-direction: row;
                    justify-content: space-between;

                    
                    .rules-box__text {
                        text-align: left;
                        margin-bottom: 0;
                        font-size: 0.9rem;
                    }
                }

                button {
                    width: auto;
                }
            }

            .contact-details {
                margin-top: 15px;

                &__title {
                    font-size: 1.35rem;
                    margin: 20px 0;
                }

                &__data {
                    font-size: 1.1rem;
                    margin-bottom: 10px;                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .contact-page {
        padding: 20px;

        &__title {
            font-size: 1.1rem;
        }

        .contact-options-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            padding-top: 15px;
            
            .contact-form {
                width: 50%;
                input,
                textarea {
                    padding: 8px 12px;
                    font-size: 0.9rem;
                }
                
                textarea {
                    min-height: 120px;
                }

                .submit-box {
                    .rules-box__text {
                        font-size: 0.7rem;
                        padding-left: 5px;

                    }
                }
            }

            .contact-details-wrapper {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .contact-details {
                width: auto;
                text-align: left;
                justify-content: flex-start;
                align-items: flex-start;
                margin-top: 0;

                &__title {
                    font-size: 1.35rem;
                    margin: 15px 0;
                }

                &__title:nth-of-type(1) {
                    margin-top: 0;
                }

                &__title:nth-of-type(2) {
                    margin-bottom: 0;
                    margin-top: 30px;
                }

                &__data {
                    font-size: 1.1rem;
                    margin-bottom: 10px;                }
            }
        }
    }
}

@media screen and (min-width: 1280px) {
    .contact-page {
        padding: 20px;

        &__title {
            font-size: 1.2rem;
        }

        .contact-options-wrapper {
            padding-top: 15px;
            
            .contact-form {
                width: 50%;
                input,
                textarea {
                    padding: 10px 14px;
                    font-size: 1rem;
                }

                .input-wrapper {
                    margin-bottom: 20px;
                }

                .input-wrapper .required-field+span::after {
                    right: 12px;
                    top: 12px;
                }
                
                textarea {
                    min-height: 150px;
                }

                .submit-box {
                    .checkbox label {
                        margin-right: 10px;
                    }
                }
            }

            .contact-details-wrapper {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .contact-details {
                width: auto;
                text-align: left;
                justify-content: flex-start;
                align-items: flex-start;
                margin-top: 0;

                &__title {
                    font-size: 1.35rem;
                    margin: 15px 0;
                }

                &__title:nth-of-type(1) {
                    margin-top: 0;
                }

                &__title:nth-of-type(2) {
                    margin-bottom: 0;
                    margin-top: 80px;
                }

                &__data {
                    font-size: 1.1rem;
                    margin-bottom: 10px;                
                }
            }
        }
    }
}

@media screen and (min-width: 1800px) {
    .page-title-box {
        padding-top: 20px;
        margin-bottom: 10px;
        .title {
            font-size: 1.4rem;
        }
    }

    .contact-page {
        padding: 25px;

        &__title {
            font-size: 1.4rem;
        }

        .contact-options-wrapper {
            padding-top: 30px;
            
            .contact-form {
                width: 50%;
                input,
                textarea {
                    padding: 12px 16px;
                }
                
                textarea {
                    min-height: 180px;
                }

                .input-wrapper .required-field+span::after {
                    right: 16px;
                    top: 16px;
                }


                .submit-box {
                    .rules-box__text {
                        font-size: 0.8rem;
                    }
                }
            }

            .contact-details {
                margin-top: 10px;

                &__title {
                    font-size: 1.5rem;
                    margin: 20px 0;
                }

                &__title:nth-of-type(1) {
                    margin-top: 0;
                }

                &__title:nth-of-type(2) {
                    margin-bottom: 0;
                    margin-top: 80px;
                }

                &__data {
                    font-size: 1.25rem;
                    margin-bottom: 15px;                
                }
            }
        }
    }
}