@media screen and (min-width: 200px) {
    .shop-background {
        background: none !important;
        padding: 0 !important;
    
        .react-tabs {
            &__tab-list {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
    
            &__tab {
                width: auto;
                padding: 8px 12px;
                background: $inactive-tab;
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
                margin-right: 3px;
                text-align: center;
                cursor: pointer;
                font-size: 0.7rem;
                vertical-align: middle;
                font-weight: 600;
                color: $text;
                opacity: 0.5;
                transition: all .1s ease-in;
            }

            li:last-of-type {
                margin-right: 0;
            }
    
            &__tab--selected {
                background: $sub-background;
                opacity: 1;
                transition: all .1s ease-in;
            }
    
            &__tab-panel {
                background: $sub-background;
                border-radius: $border-radius;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
    
            .tab-content-wrapper {
                padding: 10px 10px 0 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
            }    
        }
    }
}

@media screen and (min-width: 350px) {
    .shop-background {
        .react-tabs {    
            &__tab {
                width: auto;
                height: auto;
                // padding: 8px 8px;
                margin-right: 5px;
                font-size: 1rem;
            }
    
            .tab-content-wrapper {
                padding: 15px 15px 0 15px;
            }    
        }
    }
}

@media screen and (min-width: 500px) {
    .shop-background {
        .react-tabs {    
            &__tab-list {
                justify-content: flex-start;
            }

            &__tab {
                width: auto;
                height: auto;
                padding: 8px 12px;
                margin-right: 5px;
                font-size: 0.9rem;
            }

            &__tab-panel {
                border-radius: $border-radius;
                border-top-left-radius: 0;
            }
    
            .tab-content-wrapper {
                padding: 15px 15px 0 15px;
            }    
        }
    }
}

@media screen and (min-width: 760px) {
    .shop-background {
        .react-tabs {    
            &__tab {
                width: auto;
                height: auto;
                padding: 8px 14px;
                margin-right: 5px;
                font-size: 1rem;
            }
    
            .tab-content-wrapper {
                padding: 15px 15px 0 15px;
            }    
        }
    }
}

@media screen and (min-width: 1360px) {
    .shop-background {
        .react-tabs {    
            &__tab {
                width: auto;
                height: auto;
                padding: 10px 20px;
                margin-right: 8px;
                font-size: 1.2rem;
            }
    
            .tab-content-wrapper {
                padding: 20px 20px 0 20px;
            }    
        }
    }
}