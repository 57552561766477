@media screen and (min-width: 200px) {
    .privacy-policy {
        width: 100%;
        height: 85vh;
        overflow: auto;
        background: $background;
        color: $text;
        padding: 20px;
        border-radius: $border-radius;
        margin-top: 20px;
        line-height: 1.2rem;

        &__title {
            width: 100%;
            text-align: center;
            font-weight: 600;
            font-size: 1.3rem;
            margin-bottom: 10px;
        }

        &__sub-title {
            width: 100%;
            text-align: center;
            font-weight: 400;
            font-size: 1rem;
            margin-bottom: 10px;
        }

        &__list-title {
            width: 100%;
            text-align: left;
            font-weight: 600;
            margin-bottom: 5px;
            padding-left: 2em;
            text-indent: -2em;
        }

        &__list-content {
            width: 100%;
            text-align: left;
            padding: 0 10px;
            margin-bottom: 10px;
        }

        &__list-header {
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 10px;
        }

        ul {
            list-style: disc;
            list-style-position: inside;
            padding: 0 10px;
            padding-left: 2em;
            text-indent: -1.4em;

            li {
                margin-bottom: 10px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .privacy-policy {
        width: 700px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

@media screen and (min-width: 1024px) {
    .privacy-policy {
        width: 850px;
        margin: 30px 15px;
    }
}