@media screen and (min-width: 200px) {
    .header-button {
        position: relative;
        border-radius: $border-radius;

        padding: 8px;
        margin-left: 0;
        margin-bottom: 10px;

        color: $sub-text;
        background-color: $button;

        display: flex;
        justify-content: space-between;
        align-items: center;

        cursor: pointer;

        .ico {
            width: 20px;
            margin-right: 0; //8px
        }

        .btn-txt {
            display: none;
            font-size: 0.85rem;
            font-weight: 600;
        }

        .shop-basket-counter {
            position: absolute;
            left: -8px; //-15px
            bottom: -8px;
            border-radius: 50%;
            background: $modal-background;
            width: 20px; //30px
            height: 20px; //30px
            color: $text;
            font-weight: 600;
            font-size: 0.7rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

@media screen and (min-width: 1024px) {
    .header-button {
        padding: 5px 12px;
        margin-left: 20px;
        margin-bottom: 0;

        .ico {
            margin-right: 8px
        }

        .btn-txt {
            display: block;
        }

        .shop-basket-counter {
            position: absolute;
            left: -15px;
            bottom: -15px;
            width: 30px;
            height: 30px;
            font-size: 1.2rem;
        }
    }
}

@media screen and (min-width: 1800px) {
    .header-button {
        .ico {
            width: 25px;
        }

        .btn-txt {
            font-size: 1rem;
            font-weight: 600;
        }
    }
}