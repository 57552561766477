@media screen and (min-width: 200px) {

    .offer-component-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $sub-background;
        margin-bottom: $bottom-separator-margin;
    }

    .offer-component {
        width: 100%;
        height: auto;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        margin-bottom: $bottom-separator-margin;
    
        .offer-boxes-wrapper {
            padding-top: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }
    }

    a {
        text-decoration: none;
    }
}

@media screen and (min-width: 768px) {
    .offer-component {
        .offer-boxes-wrapper {
            flex-direction: row;
        }
    }
}

@media screen and (min-width: 1024px) {
    .offer-component {
        padding-top: 10px
    }
}

@media screen and (min-width: 1366px) {
    .offer-component {
        .offer-boxes-wrapper {
            margin-bottom: 20px;
        }
    }
}