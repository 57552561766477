@media screen and (min-width: 200px) {
    .sign-in {
        width: 100%;
        color: $text;

        &__title {
            text-align: center;
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 15px;
        }
        
        &__form {
            padding: 0 10px;
            width: 100%;
            min-width: 200px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            position: relative;

            label {
                width: 100%;
                text-align: left;
                margin-bottom: 5px;
            }

            input {
                width: 100%;
                border: none;
                background: $background;
                border-radius: $border-radius;
                padding: 8px 12px;
                margin-bottom: 10px;
                outline: none;
            }

            .error {
                border-bottom: 2px solid $button;
            }

            .error-box {
                height: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .sign-in-error {
                font-size: 0.7rem;
                color: $button;
            }

            button {
                font-size: 1rem;
                width: 100%;
                padding: 8px 10px;
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
    }
}

@media screen and (min-width: 360px) {
    .sign-in {
        
        &__form {
            min-width: 250px;

            label {
                margin-bottom: 8px;
            }
        }
    }
}

@media screen and (min-width: 760px) {
    .sign-in {

        &__title {
            font-size: 1.3rem;
        }
        
        &__form {
            min-width: 350px;

            label {
                margin-bottom: 10px;
            }
        
            button {
                margin-bottom: 15px;
                font-size: 1.3rem;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .sign-in {
        &__title {
            text-align: center;
            font-size: 1.6rem;
            font-weight: 600;
            margin: 0 20px;
            margin-bottom: 20px;
        }
    }
}